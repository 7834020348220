<template>
  <div>
    <infoHeader :content_name="'新增车库'" />

    <el-form ref="form" label-width="160px" :label-position="'left'">
      <el-form-item label="车库名">
        <el-input v-model="form.info_name"></el-input>
      </el-form-item>

      <el-form-item label="手机号">
        <el-input v-model="form.info_phone"></el-input>
      </el-form-item>

      <el-form-item label="车库简介">
        <el-input v-model="form.info_introduce"></el-input>
      </el-form-item>

      <el-form-item label="车库地区">
        <el-col :span="6">
          <el-cascader
            size="large"
            clearable= true
            :options="provinceAndCityData"
            v-model="selectedOptions">
          </el-cascader>
        </el-col>
        <!-- <el-col :span="2"><span>车库分类</span></el-col>
        <el-col :span="6">
          <el-select v-model="form.info_type" placeholder="请选择">
            <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="2"><span>车库系统版本</span></el-col>
        <el-col :span="6">
          <el-select v-model="form.info_type" placeholder="请选择">
            <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
            </el-option>
          </el-select>
        </el-col> -->

      </el-form-item>

      <el-form-item label="车库详细地址">
        <el-input v-model="form.info_address"></el-input>
      </el-form-item>

      <el-form-item label="车库楼层">
        <el-input v-model="form.info_floor"></el-input>
      </el-form-item>

      <el-form-item label="车位数">
        <el-input v-model="form.space"></el-input>
      </el-form-item>
     
      <el-form-item label="">
        <el-button type="primary" @click="submitForm">确认无误，立即创建</el-button>
      </el-form-item>
    </el-form>

    
  </div>
</template>

<script>

import { garage_create } from "@/api/manage/business/garage/garage.js";
import { provinceAndCityData } from "element-china-area-data";  // 中国省市次联选择器

export default {
  name: "start",
  data() {
    return {
      form: {},
      options:[{
        value:'ppsy-zn',
        label:'PPSY-ZN'
      }],
      provinceAndCityData,
      selectedOptions:[],
    };
  },
  watch:{
    selectedOptions(newName){
      console.log(newName);
    }
  },
  components: {},
  created() {},
  methods: {
    submitForm() {
      console.log(this.form);
      this.$confirm("是否确定增加", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "增加",
        cancelButtonText: "取消增加",
      })
        .then(() => {
          this.form.info_province = this.selectedOptions[0];
          this.form.info_city =  this.selectedOptions[1];
          garage_create(this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success("成功添加");
              this.$router.push({ name: "garage_Manage" });
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃增加" : "增加中",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
